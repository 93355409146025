import React from "react";
import loadable from "@loadable/component";
import LoadingScreen from "components/LoadingScreen/index.component";
const LandingLayout = loadable(() => import("layouts/Landing/index.layout"), {
  fallback: <LoadingScreen />,
});

export default function HomePage() {
  return (
    <>
      <LandingLayout />
    </>
  );
}
