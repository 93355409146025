import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "redux/store";
import "style/index.scss";

console.log("PROJECT_VERSION", process.env.REACT_APP_VERSION);
const _v = localStorage.getItem("_v");
if (_v && _v === process.env.REACT_APP_VERSION) {
  console.log("App version is same");
} else {
  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem("_v", process.env.REACT_APP_VERSION);
}

/** Removing console.log in production */
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.clear();
  console.disableYellowBox = true;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
