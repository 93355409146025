import React from "react";
import style from "./index.module.scss";

export default function LoadingScreen() {
  return (
    <div className={style.loading_container}>
      <div className={style.loading_content}></div>
      <div style={{ position: "absolute" }}>
        <span className={style.text_one}>Loading</span>
      </div>
    </div>
  );
}
